<template>
  <div class="w-full">
    <input ref="fileInput" type="file" class="hidden" @change="handleFileChange" />
    <Transition name="fade" mode="out-in">
      <UiButtonGhost v-if="!modelValue && !virtualFileName" :id="`upload_${id}`" :disabled @click="fileInput?.click()">
        <UiIcon name="big-add-circle" />
        {{ $t('attach_file') }}
      </UiButtonGhost>
      <div
        v-else
        class="group flex w-full flex-row items-center justify-between gap-2 rounded-xl px-4 py-3 transition-all duration-200 hover:!bg-primary-05"
      >
        <div class="flex max-w-[60%] flex-row items-center gap-2">
          <UiIcon name="file" class="min-w-6 text-black-70" />
          <span class="text-body-2 w-full truncate">
            {{ modelValue?.name || virtualFileName }}
          </span>
        </div>
        <div
          v-if="actions"
          class="flex flex-row items-center gap-3 opacity-0 transition-all duration-200 group-hover:opacity-100"
        >
          <UiButtonGhost id="download" icon type="ghost" class="!px-0" :disabled="disabled" @click="downloadFile">
            <UiIcon name="download" />
          </UiButtonGhost>
          <UiButtonGhost id="remove" icon type="ghost" class="!px-0" :disabled="disabled" @click="removeFile">
            <UiIcon name="big-close-circle-filled" />
          </UiButtonGhost>
        </div>
        <p v-else class="text-body-2 text-black-80">{{ (modelValue?.size / (1024 * 1024)).toFixed(1) + ' mb' }}</p>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits(['download-remote-file', 'file-removed'])
const modelValue = defineModel<File>()

type Props = {
  id: string
  virtualFileName?: string
  disabled?: boolean
  actions?: boolean
  deleteLocally?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  actions: true,
  deleteLocally: true,
})

const fileInput = ref()

const handleFileChange = () => {
  modelValue.value = fileInput.value?.files[0]
}

const downloadFile = () => {
  if (props.virtualFileName) {
    emits('download-remote-file')
  } else {
    const link = document.createElement('a')
    link.download = modelValue.value!.name
    link.href = URL.createObjectURL(modelValue.value!)
    link.click()
  }
}

const removeFile = () => {
  if (props.deleteLocally) {
    modelValue.value = undefined
    fileInput.value = undefined
  }

  emits('file-removed')
}
</script>

<style scoped></style>
